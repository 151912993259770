import './com_home.scss';
import config from "../Base/config";

const Home = () => {
    const refresh = () => {
        window.location.reload();
    }

    return (
        <div className={'home'}>
            <div className="logo" onClick={refresh}>
                <img src={config.imageURL + 'logo-fshare-cloud.svg'} alt="fshare logo" height={30}/>
            </div>
            <div className="text">
                <h1>Welcome to <span className={'text__name'}>fshare.cloud</span></h1>
                <div className={'text__text'}>Your customizable cloud.</div>
                <div className={'text__comingSoon'}>Coming soon.</div>
            </div>
        </div>
    )
}

export default Home;