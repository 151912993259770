import {connect} from "react-redux";
import FileDirPreviewRow from "./FileDirPreviewRow";
import config from "../Base/config";
import ContextMenuContainer from "../ContextMenu/ContextMenuContainer";
import {useState} from "react";
import {sortObjAlpha} from "../Base/functions";
import Input from "../Components/Input";
import {handleFileSelect} from "../reducers/FileReducer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const FileDirPreview = (props) => {
    let sorted_dir_arr = [];
    if (props.data.Sub_Directories && Object.keys(props.data.Sub_Directories).length > 0) {
        sorted_dir_arr = sortObjAlpha(props.data.Sub_Directories, 'Directory_Name');
    }

    let sorted_files_arr = [];
    if (props.data.Files && Object.keys(props.data.Files).length > 0) {
        sorted_files_arr = sortObjAlpha(props.data.Files, 'filename');
    }

    const handleAllFilesSelect = (key, value) => {
        let selectedFiles = [...props.selectedFiles];
        if (value) {
            for (let i in props.data.Files) {
                selectedFiles.push(props.data.Files[i]);
            }
        } else {
            selectedFiles = [];
        }
        props.handleFileSelect(selectedFiles);
    }

    const toggleFileUpload = () => {
        const file_input = document.getElementById('file_upload_input');
        if(file_input) {
            file_input.click();
        }
    }

    return (
        <>
            <div className="file__container__filePreview__dir__labels">
                <img src={config.baseURL + 'backend/loadPreviewFile?ID=285'} height={50} alt=""/>
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__checkbox">
                    <Input
                        name={'allFiles'}
                        type={'checkbox'}
                        onChange={handleAllFilesSelect}
                        checked={props.selectedFiles.length > 0 && props.selectedFiles.length === Object.keys(props.data.Files).length}
                    />
                </div>
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__filename">Filename
                </div>
                {!config.isMobile.any() &&
                    <div
                        className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__icons"></div>
                }
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__date">Date
                </div>
                <div
                    className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__size">Size
                </div>
                {!config.isMobile.any() &&
                    <div
                        className="file__container__filePreview__dir__elem file__container__filePreview__dir__elem__del"></div>
                }
            </div>
            <div className="file__container__filePreview__dir__container">
                {sorted_dir_arr && sorted_dir_arr.length > 0 &&
                    sorted_dir_arr.map((dir) => (
                        <FileDirPreviewRow key={dir} file={props.data.Sub_Directories[dir]} shared={props.shared}
                                           type={'dir'}/>
                    ))
                }
                {sorted_files_arr && sorted_files_arr.length > 0 &&
                    sorted_files_arr.map((file) => (
                        <FileDirPreviewRow key={file} file={props.data.Files[file]} shared={props.shared}
                                           type={'file'}/>
                    ))
                }
                <div className="file__container__filePreview__dir__container__addButton" onClick={toggleFileUpload}>
                    <FontAwesomeIcon icon={faPlus}/>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    selectedFiles: state.file.selectedFiles,
})

const mapDispatchToProps = {
    handleFileSelect,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileDirPreview)