import {connect} from "react-redux";
import './sidebar.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight, faGear, faHome, faShareNodes, faTrashAlt, faUser} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import config from "../Base/config";
import Nav from "../Nav/Nav";
import {NavLink} from "react-router-dom";

const SidebarContainer = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    }

    console.log(sidebarOpen);

    return (
        <div className={sidebarOpen ? "sidebar sidebar--open" : "sidebar"}>
            <div
                className={sidebarOpen ? "sidebar__openButton__container sidebar__openButton__container--open" : "sidebar__openButton__container"}>
                <div className="sidebar__openButton__container__button" onClick={toggleSidebar}>
                    <FontAwesomeIcon
                        className={sidebarOpen ? 'sidebar__openButton__container__button__icon--close' : 'sidebar__openButton__container__button__icon'}
                        icon={faChevronRight}/>
                </div>
            </div>
            <div className="sidebar__container">
                {sidebarOpen ? <img src={config.imageURL + 'logo-fshare-cloud.svg'} height={30} alt="fshare_logo"/> :
                    <img src={config.imageURL + 'favicon.svg'} alt="fshare_icon" height={30}/>}
                <div className="sidebar__elements">
                    <NavLink to={'/'}>
                        <div className="sidebar__elements__element">
                            <FontAwesomeIcon icon={faHome}/><div>{sidebarOpen ? 'Home' : ''}</div>
                        </div>
                    </NavLink>
                    <NavLink to={'/sharedOverview'}>
                        <div className="sidebar__elements__element">
                            <FontAwesomeIcon icon={faShareNodes}/><div>{sidebarOpen ? 'Shared Files' : ''}</div>
                        </div>
                    </NavLink>
                    <NavLink to={'/trash'}>
                        <div className="sidebar__elements__element">
                            <FontAwesomeIcon icon={faTrashAlt}/><div>{sidebarOpen ? 'Trash' : ''}</div>
                        </div>
                    </NavLink>
                </div>
            </div>
            <div className="sidebar__settings">
                <div className="sidebar__elements">
                    <div className="sidebar__elements__element">
                        <FontAwesomeIcon icon={faUser}/><div>{sidebarOpen ? 'User' : ''}</div>
                    </div>
                    <div className="sidebar__elements__element">
                        <FontAwesomeIcon icon={faGear}/><div>{sidebarOpen ? 'Settings' : ''}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SidebarContainer)