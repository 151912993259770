import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faEye, faEyeSlash, faLock, faUser} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

const Input = (props) => {
    const [pwVisible, setPwVisible] = useState(false);

    const onChange = (e) => {
        let value = props.type === 'checkbox' ? e.target.checked : e.target.value;
        props.onChange(props.name, value);
    }

    const onKeyDown = (e) => {
        if (props.onKeyDown) {
            props.onKeyDown(e);
        }
    }

    const input_class = props.flex ? 'input input--flex' : props.flex_reverse ? 'input input--flex input input--flex-reverse' : 'input';

    const icon_options = {
        mail_name: faEnvelope,
        password : faLock,
    }

    const togglePwVisible = () => {
        setPwVisible(!pwVisible);
    }

    return (
        <div className={input_class}>
            {props.label &&
                <label htmlFor={props.name}>{props.label}</label>
            }
            {Object.keys(icon_options).includes(props.name) &&
                <div className="input__icon_container">
                    <div className="input__icon">
                        <FontAwesomeIcon icon={icon_options[props.name]}/>
                    </div>
                </div>
            }
            <input
                className={props.className}
                type={props.type === 'password' ? pwVisible ? 'text' : props.type : props.type || 'text'}
                name={props.name}
                value={props.value}
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={props.placeholder || props.label || ''}
                required={props.required}
                readOnly={props.readOnly}
                disabled={props.disabled}
                checked={props.checked}
                autoFocus={props.autoFocus}
                style={props.style}
                autoComplete={props.autoComplete}
            />
            {props.type === 'password' &&
                <div className="input__pw_visible_container">
                    <div className="input__pw_visible" onClick={togglePwVisible}>
                        <FontAwesomeIcon icon={pwVisible ? faEyeSlash : faEye} />
                    </div>
                </div>
            }
        </div>
    )
}

export default Input;