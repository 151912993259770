import {QRCodeSVG} from "qrcode.react";

const  QRCode = ({QRData}) => {
    return (
        <div className="qr_code">
            <QRCodeSVG value={QRData} />
        </div>
    )
}

export default QRCode;