import {connect} from "react-redux";
import './trash.scss';
import {useEffect} from "react";
import {loadData} from "../Base/functions";
import {storeTrashList} from "../reducers/FileReducer";
import FileDirPreviewRow from "../Files/FileDirPreviewRow";

const TrashContainer = (props) => {
    useEffect(() => {
        loadTrashList();
    }, [])

    const loadTrashList = () => {
        props.loadData({
            route   : 'loadTrashList',
            callBack: props.storeTrashList,
            response: () => null,
        })
    }

    if(!props.trashList) return null;

    return (
        <div className="mainBox trash">
            <div className="mainBox__container trash__container">
                {Object.keys(props.trashList).map((item) => (
                    <FileDirPreviewRow
                        key={item}
                        file={props.trashList[item]}
                        shared={0}
                        type={'file'}
                    />
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    trashList: state.file.trash.list,
})

const mapDispatchToProps = {
    loadData,
    storeTrashList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TrashContainer);