import {connect} from "react-redux";
import {Outlet} from "react-router-dom";
import {useEffect, useState} from "react";

import {loadData} from "./Base/functions";
import {storeUser, toggleUserModal} from "./reducers/UserReducer";
import Nav from "./Nav/Nav";
import ModalContainer from "./Modal/ModalContainer";
import Forbidden from "./Base/Forbidden";
import config from "./Base/config";
import Home from "./Com/Home";
import UserContainer from "./User/UserContainer";
import {i18n} from "./reducers/TranslationReducer";
import SidebarContainer from "./Sidebar/SidebarContainer";

const AppContent = (props) => {
    const [shared, setShared] = useState(false);

    useEffect(() => {
        initial_load();
    }, [])

    const initial_load = () => {
        props.loadData({
            route   : 'loadUserData',
            callBack: props.storeUser,
            response: () => null,
        })
        props.loadData({
            route   : 'test',
            callBack: (data) => {console.log(data)},
            response: () => null,
        })
    }

    return (
        <div className={'main'} id={'main'}>
            {config.activeSite === 'com' ?
                <>
                    <Home/>
                </>
                :
                <>
                    <SidebarContainer />
                    <Nav/>
                    <div className="content">
                        <Outlet/>
                    </div>
                    {props.modal.active &&
                        <ModalContainer/>
                    }
                    {props.userModalOpen && !props.file.shared &&
                        <UserContainer/>
                    }
                    {/*{props.file.tree && Object.keys(props.file.tree).length === 0 && !props.userModalOpen &&*/}
                    {/*    <div className="file__container__filePreview__welcome">*/}
                    {/*        <div className="file__container__filePreview__welcome__content">*/}
                    {/*            <h3>👋 Welcome to your private cloud</h3>*/}
                    {/*            <p>On your left you can navigate through your Files.</p>*/}
                    {/*            <p>The right side is for Previews.</p>*/}
                    {/*            <p>Above the preview window there are a few Icons for creating folders, sharing or download*/}
                    {/*                files and more.</p>*/}
                    {/*            <p>Have fun exploring your cloud.</p>*/}
                    {/*            {!props.user.User_ID &&*/}
                    {/*                <div className="file__container__filePreview__welcome__content__buttonContainer">*/}
                    {/*                    <div className="file__container__filePreview__welcome__content__buttonContainer__button"*/}
                    {/*                         onClick={() => props.toggleUserModal(true)}>*/}
                    {/*                        {i18n.getStarted}*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*}*/}
                </>
            }
        </div>
    )
}

const mapStateToProps = (state) => ({
    file         : state.file,
    user         : state.user,
    modal        : state.modal,
    userModalOpen: state.user.modalOpen,
})

const mapDispatchToProps = {
    loadData,
    storeUser,
    toggleUserModal,
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContent);