import UserReducer from "./UserReducer";
import FileReducer from "./FileReducer";
import ModalReducer from "./ModalReducer";
import TranslationReducer from "./TranslationReducer";

const rootReducer = (state = {}, action) => ({
    user        : UserReducer(state.user, action),
    file        : FileReducer(state.file, action),
    modal       : ModalReducer(state.modal, action),
    translations: TranslationReducer(state.translations, state.user, action),
})

export default rootReducer;