import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faBan,
    faPen,
    faPlusCircle,
    faShareNodes,
    faSpinner,
    faTimes,
    faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import './modal.scss';
import {resetModalInfo, setModalActive, setModalInfo, setUploadProgress} from "../reducers/ModalReducer";
import {useEffect, useState} from "react";
import Input from "../Components/Input";
import {containsSpecialCharacter, fileUpload, getDirArray, getNavigateToDirData, saveData} from "../Base/functions";
import {
    completeAddNewFolder,
    completeDeleteDir,
    completeDeleteFile,
    setActiveFile,
    storeFileList
} from "../reducers/FileReducer";
import Form from "../Components/Form";
import config from "../Base/config";
import QRCode from "../Components/QRCode";

const ModalContainer = (props) => {
    const [folderName, setFolderName] = useState('');

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        }
    }, [])

    const handleKeyDown = (e) => {
        if (e.key === 'Escape') {
            closeModal();
        }
    }

    let copyTimeout = null

    const copyLink = () => {
        clearTimeout(copyTimeout);
        navigator.clipboard.writeText(props.sharedData.shareLink).then(() => {
            props.setModalInfo({
                active: true,
                type  : 'info',
                text  : 'Link in die Zwischenablage kopiert!',
            })
        }).catch((err) => {
            props.setModalInfo({
                active: true,
                type  : 'warning',
                text  : 'Fehler beim Kopieren des Links!',
            })
        });
        copyTimeout = setTimeout(() => {
            props.resetModalInfo();
        }, 2000)
    }

    const headerIcon = {
        shareLink    : faShareNodes,
        newFolder    : faPlusCircle,
        progress     : faSpinner,
        moveToTrash  : faTrashAlt,
        confirmDelete: faTrashAlt,
        rename       : faPen,
    }[props.modal.type] || null;

    const deleteFileText = (
        props.modal &&
        props.modal.data &&
        props.modal.data.type === 'file' ?
            props.modal.data.filename &&
            props.modal.data.extension &&
            '"' + (props.modal.data.filename + '.' + props.modal.data.extension) + '"'
            :
            props.modal.data.type === 'dir' ?
                '"' + props.modal.data.Directory_Name + '"'
                :
                props.file.selectedFiles.length > 0 ?
                    'all selected files'
                    :
                    ''
    );

    const headerText = {
        shareLink    : 'Share "' + props.sharedData.filename + '"',
        newFolder    : 'New Folder',
        progress     : 'Upload',
        moveToTrash  : 'Move ' + deleteFileText + ' to trash?',
        confirmDelete: 'Are you sure you want to delete ' + deleteFileText + '?',
        rename       : 'Rename ' + deleteFileText + '?',
    }[props.modal.type] || null;

    const buttonText = {
        shareLink    : 'Copy Link',
        newFolder    : 'Add Folder',
        progress     : 'Cancel upload',
        moveToTrash  : 'Move to trash',
        confirmDelete: 'Delete',
        rename       : 'Rename',
    }[props.modal.type] || null;

    const onChange = (key, value) => {
        setFolderName(value);
    }

    const handleButtonClick = () => {
        switch (props.modal.type) {
            case 'shareLink':
                copyLink();
                break;
            case 'newFolder':
                addNewFolder();
                break;
            case 'progress':
                cancelUpload();
                break;
            case 'moveToTrash':
                moveFileToTrash();
                break;
            case 'confirmDelete':
                deleteFile();
                break;
            case 'rename':
                renameFile();
                break;
            default:
        }
    }

    const renameFile = () => {
        if (props.modal.data && Object.keys(props.modal.data).length > 0) {
            const fd = new FormData();
            const send_data = props.modal.data && Object.keys(props.modal.data).length > 0 ? props.modal.data : null;
            if (send_data) {
                fd.append('data', JSON.stringify(send_data));

                props.saveData({
                    route   : 'renameFile',
                    formData: fd,
                    callBack: completeRenameFile,
                    response: () => null,
                })
            }
        }
    }

    const completeRenameFile = (data) => {
        props.storeFileList(data);
    }

    const moveFileToTrash = () => {
        if ((props.modal.data && Object.keys(props.modal.data).length > 0) || props.file.selectedFiles.length > 0) {
            const fd = new FormData();
            const send_data = props.modal.data && Object.keys(props.modal.data).length > 0 ? [props.modal.data] : props.file.selectedFiles;
            fd.append('data', JSON.stringify(send_data));

            if (config.isLocalhost) {
                props.saveData({
                    route   : 'moveFileToTrash',
                    formData: fd,
                    callBack: completeDeleteFile,
                    response: () => null,
                })
            } else {
                fileUpload({
                    route   : 'moveFileToTrash',
                    formData: fd,
                    progress: handleProgress,
                    callBack: completeDeleteFile,
                    response: () => null,
                })
            }
        }
    }

    const deleteFile = () => {
        if ((props.modal.data && Object.keys(props.modal.data).length > 0) || props.file.selectedFiles.length > 0) {
            const fd = new FormData();
            const send_data = props.modal.data && Object.keys(props.modal.data).length > 0 ? [props.modal.data] : props.file.selectedFiles;
            fd.append('data', JSON.stringify(send_data));

            if (config.isLocalhost) {
                props.saveData({
                    route   : 'deleteFile',
                    formData: fd,
                    callBack: completeDeleteFile,
                    response: () => null,
                })
            } else {
                fileUpload({
                    route   : 'deleteFile',
                    formData: fd,
                    progress: handleProgress,
                    callBack: completeDeleteFile,
                    response: () => null,
                })
            }
        }
    }

    const handleProgress = (progress) => {
        if (!props.modal.active) {
            props.setModalActive({
                active: true,
                type  : 'progress',
            })
        }
        props.setUploadProgress(progress);
    }

    const completeDeleteFile = async (data) => {
        await props.storeFileList(data);
        if (props.modal.data && Object.keys(props.modal.data).length > 0) {
            if (props.modal.data.type === 'dir') {
                props.completeDeleteDir({
                    ID: props.modal.data.ID,
                });
            } else {
                props.completeDeleteFile({
                    File_ID: props.modal.data.File_ID,
                });
            }
        } else {
            props.completeDeleteFile({
                ID_Array: props.file.selectedFiles,
            });
        }
        closeModal();
    }

    const cancelUpload = () => {
        if (props.file.xhr) {
            props.file.xhr.abort();
            closeModal();
        }
    }

    const addNewFolder = () => {
        // if (props.modal.data && Object.keys(props.modal.data).length > 0 && props.modal.data.path) {
        const fd = new FormData();
        const send_data = {
            folderName,
            dir: props.file.active.dir,
        };
        fd.append('data', JSON.stringify(send_data));

        props.saveData({
            route   : 'addNewFolder',
            formData: fd,
            callBack: completeAddNewFolder,
            response: handleError,
        })
        // }
    }

    const completeAddNewFolder = async (data) => {
        if (data.updated_list) {
            await props.storeFileList(data.updated_list);
        }
        if (data.new_dir) {
            props.completeAddNewFolder(data.new_dir)
        }
        // let index = 0;
        // if (props.file.path && props.file.path.includes('/')) {
        //     index = Number(props.file.path.split('/').length) - 1;
        // }
        // // props.setActiveFile(getNavigateToDirData({ list: data }, index, getDirArray(props.file.path)));
        closeModal();
    }

    const handleError = (data) => {
        if (data && data.errorData) {
            const error_msg = data && data.errorData && data.errorData.error && data.errorData.error.error_msg && data.errorData.error.error_msg;
            alert(error_msg);
        }
    }

    const closeModal = () => {
        props.setModalActive({
            active: false
        });
    }

    return (
        <div className="modal">
            <div className="modal__container">
                <div className="modal__container__header">
                    <div>
                        <FontAwesomeIcon
                            className={props.modal.type === 'progress' ? "modal__container__header__icon--progress" : "modal__container__header__icon"}
                            icon={headerIcon}/>
                        <div className="modal__container__header__text">{headerText}</div>
                    </div>
                    {props.modal.type !== 'progress' &&
                        <div className="modal__container__header__closeIcon"
                             onClick={closeModal}>
                            <FontAwesomeIcon icon={faTimes}/>
                        </div>
                    }
                </div>

                <div className="modal__container__content">
                    {props.modal.type === 'shareLink' &&
                        <>
                            <div className="modal__container__content__link">
                                {props.sharedData && props.sharedData.shareLink ? props.sharedData.shareLink : ''}
                            </div>
                            <div className="modal__container__content__link__qr__container">
                                <div className="modal__container__content__link__qr__code">
                                    <QRCode QRData={props.sharedData.shareLink} />
                                </div>
                            </div>
                        </>
                    }
                    {props.modal.type === 'newFolder' &&
                        <div className="modal__container__content__textInput">
                            <Form onSubmit={containsSpecialCharacter(folderName) ? () => null : addNewFolder} hide>
                                <Input
                                    className={containsSpecialCharacter(folderName) ? 'input__error' : ''}
                                    id={'newFolder'}
                                    name={'newFolder'}
                                    onChange={onChange}
                                    value={folderName}
                                    autoFocus
                                />
                            </Form>
                        </div>
                    }
                    {props.modal.type === 'rename' &&
                        <div className="modal__container__content__textInput">
                            <Form onSubmit={containsSpecialCharacter(folderName) ? () => null : renameFile} hide>
                                <Input
                                    className={containsSpecialCharacter(folderName) ? 'input__error' : ''}
                                    id={'newFolder'}
                                    name={'newFolder'}
                                    onChange={onChange}
                                    value={folderName}
                                    autoFocus
                                />
                            </Form>
                        </div>
                    }
                    {props.modal.type === 'progress' &&
                        <div className="modal__container__content__progress">
                            <div className="modal__container__content__progress__bar">
                                <div className="modal__container__content__progress__bar__progress"
                                     style={{ width: props.modal.progress + '%' }}></div>
                            </div>
                        </div>
                    }
                    <div className="modal__container__content__infoButtonContainer">
                        <div
                            className={"modal__container__content__infoButtonContainer__infoText"}>
                            {props.modal.info.active &&
                                <span
                                    className={props.modal.info.type === 'info' ?
                                        "modal__container__content__infoButtonContainer__infoText modal__container__content__infoButtonContainer__infoText--info"
                                        :
                                        props.modal.info.type === 'warning' ?
                                            "modal__container__content__infoButtonContainer__infoText modal__container__content__infoButtonContainer__infoText--warning"
                                            :
                                            null
                                    }>
                                    {props.modal.info.text}
                                </span>}
                            {['newFolder', 'rename'].includes(props.modal.type) && containsSpecialCharacter(folderName) &&
                                <div
                                    className="modal__container__content__infoButtonContainer__infoText modal__container__content__infoButtonContainer__infoText--warning">
                                    Cannot contain: <span>{config.specialCharacters.join(' ')}</span>
                                </div>
                            }
                        </div>
                        <div
                            className={['progress', 'confirmDelete'].includes(props.modal.type) ? "button button--cancel" : (['newFolder', 'rename'].includes(props.modal.type) && (containsSpecialCharacter(folderName) || folderName.trim() === '')) ? "button button--disabled" : "button"}
                            id={props.modal.type === 'progress' ? 'cancelUploadButton' : ''}
                            onClick={handleButtonClick}>
                            {buttonText}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    modal     : state.modal,
    sharedData: state.file.sharedData,
    file      : state.file,
})

const mapDispatchToProps = {
    setModalActive,
    setModalInfo,
    resetModalInfo,
    saveData,
    setActiveFile,
    storeFileList,
    setUploadProgress,
    completeAddNewFolder,
    completeDeleteFile,
    completeDeleteDir,
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);