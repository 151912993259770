import {connect} from "react-redux";
import './shared_overview.scss';
import {useEffect} from "react";
import {storeSharedOverviewList} from "../reducers/FileReducer";
import {loadData} from "../Base/functions";
import FileDirPreviewRow from "../Files/FileDirPreviewRow";

const SharedOverviewContainer = (props) => {
    useEffect(() => {
        loadSharedOverviewList();
    }, [])

    const loadSharedOverviewList = () => {
        props.loadData({
            route   : 'loadSharedOverviewList',
            callBack: props.storeSharedOverviewList,
            response: () => null,
        })
    }

    if (!props.overviewList) return null;

    console.log(props.overviewList);

    return (
        <div className={'mainBox overview'}>
            <div className="mainBox__container overview__container">
                {Object.keys(props.overviewList).map((item) => (
                    <FileDirPreviewRow
                        key={item}
                        file={props.overviewList[item]}
                        shared={1}
                        type={'file'}
                    />
                ))}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    overviewList: state.file.sharedOverview.list,
})

const mapDispatchToProps = {
    loadData,
    storeSharedOverviewList,
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedOverviewContainer);