import {connect} from "react-redux";
import {useEffect, useState} from "react";
import {downloadFile, saveData} from "../Base/functions";
import {storeFileList, storeSharedData} from "../reducers/FileReducer";
import FilePreview from "../Files/FilePreview";
import './shared.scss';
import FilesHeader from "../Files/FilesHeader";
import config from "../Base/config";
import File from "../Files/File";

const SharedFilesContainer = (props) => {
    const [hash, setHash] = useState('');

    useEffect(() => {
        const url = new URL(window.location.href);
        const hash = url.searchParams.get('file');

        loadSharedFiles(hash);
    }, [])

    const loadSharedFiles = (hash) => {
        const fd = new FormData();
        fd.append('hash', hash);

        props.saveData({
            route   : 'loadSharedFiles',
            formData: fd,
            callBack: storeSharedFiles,
        })
    }

    const storeSharedFiles = (data) => {
        console.log(data);
        props.storeSharedData(data);
    }

    return (
        <div className="file">
            {!config.isMobile.any() &&
                <FilesHeader shared />
            }
            <div className={'file__container'}>
                {config.isMobile.any() &&
                    <FilesHeader shared />
                }
                <FilePreview
                    shared/>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    sharedData: state.file.sharedData,
})

const mapDispatchToProps = {
    saveData,
    storeFileList,
    storeSharedData,
}

export default connect(mapStateToProps, mapDispatchToProps)(SharedFilesContainer);